define("fx/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class="footer">
    <div class="inner">
      <ul class="footer-list">
        <li>
          <a href="https://www.d-riskfx.com/privacy-policy?utm_source=application" class="footer-link" target="_blank" rel="noopener noreferrer">
            {{t "footer.privacy-policy"}}
          </a>
        </li>
  
        <li>
          <a href="https://d-riskfx.com/qopcinewqcqwpecun238282734ccb2cbcb238c2cbcbcammasaxlpwec2803284?utm_source=application" class="footer-link" target="_blank" rel="noopener noreferrer">
            {{t "footer.licence-agreement"}}
          </a>
        </li>
      </ul>
  
      {{yield}}
    </div>
  </footer>
  */
  {
    "id": "dY8y7heR",
    "block": "[[[10,\"footer\"],[14,0,\"footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"inner\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"footer-list\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[14,6,\"https://www.d-riskfx.com/privacy-policy?utm_source=application\"],[14,0,\"footer-link\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n          \"],[1,[28,[35,0],[\"footer.privacy-policy\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[14,6,\"https://d-riskfx.com/qopcinewqcqwpecun238282734ccb2cbcb238c2cbcbcammasaxlpwec2803284?utm_source=application\"],[14,0,\"footer-link\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n          \"],[1,[28,[35,0],[\"footer.licence-agreement\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"t\",\"yield\"]]",
    "moduleName": "fx/components/footer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});